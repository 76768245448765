export const actions = {
  set_state: "stokProduk/set_state",
  get_stokProduk: "stokProduk/get",
  get_index: "stokProduk/get_index",
  get_by_id: "stokProduk/get_by_id",
  get_datatable_stokProduk: "stokProduk/datatable",
  create_stokProduk: "stokProduk/create",
  update_stokProduk: "stokProduk/update",
  delete_stokProduk: "stokProduk/delete",
}
