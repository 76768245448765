export const actions = {
  set_state: "pasar/set_state",
  get_pasar: "pasar/get",
  get_index: "pasar/get_index",
  get_by_id: "pasar/get_by_id",
  get_datatable_pasar: "pasar/datatable",
  get_counting: "pasar/get_counting",
  create_pasar: "pasar/create",
  update_pasar: "pasar/update",
  delete_pasar: "pasar/delete",
  change_radius_pasar: "pasar/change_radius",
}
