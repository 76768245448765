import CryptoAES from "crypto-js/aes"
import CryptoENC from "crypto-js/enc-utf8"
import moment from "moment"

const AUTH_CRED = "AUTH_CRED_SALES"
const secretKey = "h75S+hWkTyHnxJnASALESCATRAUPAYA_r1z4l"

export function setAuthCredentials(dataParam = {}) {
  if (dataParam?.userId) {
    let encryptData = CryptoAES.encrypt(
      JSON.stringify(dataParam),
      secretKey
    ).toString()

    localStorage.setItem(AUTH_CRED, encryptData, { expires: 1 })
  } else {
    localStorage.removeItem(AUTH_CRED)
  }
}

export function getAuthCredentials() {
  let authCred = localStorage.getItem(AUTH_CRED) ?? ""
  let returnData = {
    userId: null,
    fullname: null,
    email: null,
    userApiToken: null,
    role: null,
    sites: [],
    roles: [],
    permission: null,
  }
  if (authCred) {
    let decryptText = CryptoAES.decrypt(authCred, secretKey).toString(CryptoENC)
    if (IsJsonString(decryptText)) {
      let parseData = JSON.parse(decryptText)
      if (moment(parseData?.expiration) > moment()) {
        return parseData
      }
    }
  }
  return returnData
}

export function getUserId() {
  let authCred = localStorage.getItem(AUTH_CRED) ?? ""
  let userId = null
  if (authCred) {
    let decryptText = CryptoAES.decrypt(authCred, secretKey).toString(CryptoENC)
    if (IsJsonString(decryptText)) {
      let parseData = JSON.parse(decryptText)
      if (moment(parseData?.expiration) > moment()) {
        return parseData?.userId ?? null
      }
    }
  }
  return userId
}

export function hasPermission(_roles) {
  // return true
  if (_roles) {
    let authCred = getAuthCredentials()
    if (authCred?.permission) {
      let rolesArr = authCred.permission.split(",")
      if (
        rolesArr.filter((x) => (_roles ?? "").split(",").includes(x) && x)
          .length > 0
      ) {
        return true
      }
    }
  }
  return false
}

export function isUserLoggedIn() {
  let authCred = getAuthCredentials()
  return authCred?.userId
}

export function removeAuthCredentials() {
  localStorage.removeItem(AUTH_CRED)
  localStorage.removeItem("RETURN_URL")
}

const IsJsonString = (str) => {
  try {
    str = str ?? ""
    JSON.parse(str)
  } catch (e) {
    // console.log("is not json")
    return false
  }
  return true
}
