/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from "redux-saga/effects"

import { actions } from "./action"
import { serviceUser } from "./api"
import catchErrorResponse from "@helpers/catchErrorResponse"
import { getAuthCredentials, getUserId } from "@helpers/Auth"

function setState(payload) {
  return put({
    type: "user/set_state",
    payload,
  })
}

function load(payload) {
  return setState({ loading: payload })
}

export function* getById({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  if (payload) {
    const response = yield call(serviceUser.getById, token, payload)
    if (response?.status < 200 || response?.status >= 300)
      catchErrorResponse(response)
    if (response?.status === 200) {
      yield setState({
        loading: false,
        rowData: response?.data?.data ?? [],
      })
    } else yield load(false)
  }
}

export function* getAccount({ payload }) {
  let userId = getUserId()
  let formData = new FormData()
  formData.append("userId", userId)

  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  if (userId) {
    const response = yield call(serviceUser.getById, token, formData)
    if (response?.status < 200 || response?.status >= 300)
      catchErrorResponse(response)
    if (response?.status === 200) {
      yield setState({
        loading: false,
        rowData: response?.data?.data.length ? response?.data?.data[0] : {},
      })
    } else yield load(false)
  }
}

export function* getDatatableUser({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceUser.getDatatable, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      listData: response.data?.data ?? [],
      meta: {
        ...payload,
        ...{ total: response.data?.recordsFiltered ?? 0 },
      },
    })
  } else yield load(false)
}

export function* createUser({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceUser.create, token, payload)

  if (response?.status < 200 || response?.status >= 300) {
    catchErrorResponse(response)
  }

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* updateUser({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceUser.update, token, { ...payload })

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* deleteUser({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceUser.delete, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* changePhoto({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceUser.changePhoto, token, payload)

  if (response?.status < 200 || response?.status >= 300) {
    catchErrorResponse(response)
  }

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export default function* userSaga() {
  yield all([
    takeEvery(actions.get_by_id, getById),
    takeEvery(actions.get_datatable_user, getDatatableUser),
    takeEvery(actions.create_user, createUser),
    takeEvery(actions.update_user, updateUser),
    takeEvery(actions.delete_user, deleteUser),
    takeEvery(actions.get_account, getAccount),
    takeEvery(actions.change_photo, changePhoto),
  ])
}
