/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from "redux-saga/effects"

import { actions } from "./action"
import { serviceSales } from "./api"
import catchErrorResponse from "@helpers/catchErrorResponse"
import { getAuthCredentials } from "@helpers/Auth"

function setState(payload) {
  return put({
    type: "sales/set_state",
    payload,
  })
}

function load(payload) {
  return setState({ loading: payload })
}

export function* getSales({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceSales.get, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      listData: response.data?.data ?? [],
    })
  } else yield load(false)
}

export function* getSalesJadwal({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceSales.getSalesJadwal, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      listSalesJadwal: response.data?.data ?? [],
    })
  } else yield load(false)
}

export function* getIndex({ payload }) {
  yield setState({
    loading: true,
  })

  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceSales.index, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      indexData: response.data?.data ?? {},
    })
  } else yield load(false)
}

export function* getById({ payload, callback }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceSales.getById, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      rowData: response.data?.data[0] ?? {},
    })
    if (callback) {
      callback({
        error: response?.status < 200 || response?.status >= 300,
        status: response?.status,
        message: response?.message,
        data: response?.data,
      })
    }
  } else yield load(false)
}

export function* getDatatableSales({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceSales.getDatatable, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      listData: response.data?.data ?? [],
      meta: {
        ...payload,
        ...{ total: response.data?.recordsFiltered ?? 0 },
      },
    })
  } else yield load(false)
}

export function* getDatatableTarget({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceSales.getDatatableTarget, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      listDataTarget: response.data?.data ?? [],
      metaTarget: {
        ...payload,
        ...{ total: response.data?.recordsFiltered ?? 0 },
      },
    })
  } else yield load(false)
}

export function* createSales({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceSales.create, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* createSalesTarget({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceSales.createTarget, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* updateSales({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceSales.update, token, { ...payload })

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* deleteSales({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceSales.delete, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* getPerformaChart({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceSales.getPerformanceChart, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      performanceChart: response.data?.data ?? {},
    })
  } else yield load(false)
}

export function* getDatatablePerformaData({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceSales.getPerformanceData, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      performanceData: response.data?.data ?? [],
      metaPerformanceData: {
        ...payload,
        ...{ total: response.data?.recordsFiltered ?? 0 },
      },
    })
  } else yield load(false)
}

export function* getPerformaCount({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceSales.getPerformanceCount, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      performanceCount: response.data?.data.length
        ? response.data?.data[0]
        : {} ?? [],
    })
  } else yield load(false)
}

export function* importTargetSales({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceSales.importTargetSales, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* downloadTemplate({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceSales.downloadTemplate, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export default function* salesSaga() {
  yield all([
    takeEvery(actions.get_index, getIndex),
    takeEvery(actions.get_sales, getSales),
    takeEvery(actions.get_sales_jadwal, getSalesJadwal),
    takeEvery(actions.get_by_id, getById),
    takeEvery(actions.get_datatable_sales, getDatatableSales),
    takeEvery(actions.create_sales, createSales),
    takeEvery(actions.update_sales, updateSales),
    takeEvery(actions.delete_sales, deleteSales),
    takeEvery(actions.get_datatable_target, getDatatableTarget),
    takeEvery(actions.create_sales_target, createSalesTarget),
    takeEvery(actions.get_performance_chart, getPerformaChart),
    takeEvery(actions.get_performance_data, getDatatablePerformaData),
    takeEvery(actions.get_performance_count, getPerformaCount),
    takeEvery(actions.import_target_sales, importTargetSales),
    takeEvery(actions.download_template, downloadTemplate),
  ])
}
