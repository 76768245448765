/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from "redux-saga/effects"

import { actions } from "./action"
import { servicePesanan } from "./api"
import catchErrorResponse from "@helpers/catchErrorResponse"
import { getAuthCredentials } from "@helpers/Auth"

function setState(payload) {
  return put({
    type: "pesanan/set_state",
    payload,
  })
}

function load(payload) {
  return setState({ loading: payload })
}

export function* getPesanan({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(servicePesanan.get, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      listData: response.data?.data ?? [],
    })
  } else yield load(false)
}

export function* getIndex({ payload }) {
  yield setState({
    loading: true,
  })

  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(servicePesanan.index, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      indexData: response.data?.data ?? {},
    })
  } else yield load(false)
}

export function* getById({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(servicePesanan.getById, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      rowData: response.data?.data[0] ?? {},
    })
  } else yield load(false)
}

export function* getPesananDetail({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(servicePesanan.getPesananDetail, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      pesananDetail: response.data?.data ?? {},
    })
  } else yield load(false)
}

export function* getDatatablePesanan({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(servicePesanan.getDatatable, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      listData: response.data?.data ?? [],
      meta: {
        ...payload,
        ...{ total: response.data?.recordsFiltered ?? 0 },
      },
    })
  } else yield load(false)
}

export function* getDatatableTagihan({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(
    servicePesanan.getDatatableTagihan,
    token,
    payload
  )
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      listTagihan: response.data?.data ?? [],
      metaTagihan: {
        ...payload,
        ...{ total: response.data?.recordsFiltered ?? 0 },
      },
    })
  } else yield load(false)
}

export function* lastUpdateList({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(servicePesanan.lastUpdateList, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      log: response.data?.data ?? [],
    })
  } else yield load(false)
}

export function* reviewPesanan({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(servicePesanan.reviewPesanan, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* batalPesanan({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(servicePesanan.batalPesanan, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* acceptNoo({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(servicePesanan.acceptNoo, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* registerOutlet({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(servicePesanan.registerOutlet, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* addUpdatePesanan({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(servicePesanan.addUpdatePesanan, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* getTagihan({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(servicePesanan.getTagihan, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      tagihan: response.data?.data ?? {},
    })
  } else yield load(false)
}

export function* acceptTagihan({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(servicePesanan.acceptTagihan, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* updateTagihan({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(servicePesanan.updateTagihan, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* validateTagihan({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(servicePesanan.validateTagihan, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export default function* pesananSaga() {
  yield all([
    takeEvery(actions.get_pesanan, getPesanan),
    takeEvery(actions.get_by_id, getById),
    takeEvery(actions.get_pesanan_detail, getPesananDetail),
    takeEvery(actions.get_datatable_pesanan, getDatatablePesanan),
    takeEvery(actions.get_datatable_tagihan, getDatatableTagihan),
    takeEvery(actions.get_index, getIndex),
    takeEvery(actions.review_pesanan, reviewPesanan),
    takeEvery(actions.batal_pesanan, batalPesanan),
    takeEvery(actions.accept_noo, acceptNoo),
    takeEvery(actions.register_outlet, registerOutlet),
    takeEvery(actions.add_update_pesanan, addUpdatePesanan),
    takeEvery(actions.get_tagihan, getTagihan),
    takeEvery(actions.acc_tagihan, acceptTagihan),
    takeEvery(actions.update_tagihan, updateTagihan),
    takeEvery(actions.validate_tagihan, validateTagihan),
    takeEvery(actions.last_update_list, lastUpdateList),
  ])
}
