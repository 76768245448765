import numeral from "numeral"

const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, "")
}

function colonStrToJson(str) {
  let fields = []
  let fieldStrings = str.split(/[\r\n]*---[\r\n]*/)
  for (let i = 0; i < fieldStrings.length; i++) {
    if (fieldStrings[i] == "") continue

    let obj = {}
    let propsStrings = fieldStrings[i].split("\n")
    for (let j = 0; j < propsStrings.length; j++) {
      let keyvalue = propsStrings[j].split(":")
      let key = camelize(keyvalue[0].trim()).replaceAll(" ", "")
      obj[key] = (keyvalue[1] ?? "").trim()
    }
    fields.push(obj)
  }
  return fields
}

function isJsonString(str) {
  try {
    JSON.parse(str ?? "")
  } catch (e) {
    return false
  }
  return true
}

function isEmail(val) {
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!regEmail.test(val)) {
    return "Invalid Email"
  }
}

function hexColorIslight(color) {
  const hex = color.replace("#", "")
  const cr = parseInt(hex.substr(0, 2), 16)
  const cg = parseInt(hex.substr(2, 2), 16)
  const cb = parseInt(hex.substr(4, 2), 16)
  const brightness = (cr * 299 + cg * 587 + cb * 114) / 1000
  return brightness > 155
}

const isDeviation = (value, type, low, high) => {
  if (type == "input") {
    return (
      (value < (low || low == 0 ? low : value) ||
        value > (high || high == 0 ? high : value)) &&
      value
    )
  } else if (type == "select") {
    return low.split(",").includes(value)
  }
}

const isNullEmptyOrUndefined = (value) => {
  if (
    value === "" ||
    value == null ||
    value == undefined ||
    (value ?? "").length == 0 ||
    (typeof value === "object" &&
      value !== null &&
      Object.keys(value).length === 0)
    // Object.keys(value).length === 0
  ) {
    return true
  } else {
    return false
  }
}

const doEval = (text) => {
  try {
    return eval(text)
  } catch (e) {
    return false
  }
}

function shortenNumber(num, decimalPlaces) {
  var str,
    suffix = ""

  decimalPlaces = decimalPlaces || 0
  num = +num

  var factor = Math.pow(10, decimalPlaces)

  //99999 -> 99.9K

  if (num < 1000) {
    str = num
  } else if (num < 1000000) {
    str = Math.floor(num / (1000 / factor)) / factor
    suffix = "K"
  } else if (num < 1000000000) {
    str = Math.floor(num / (1000000 / factor)) / factor
    suffix = "M"
  } else if (num < 1000000000000) {
    str = Math.floor(num / (1000000000 / factor)) / factor
    suffix = "B"
  } else if (num < 1000000000000000) {
    str = Math.floor(num / (1000000000000 / factor)) / factor
    suffix = "T"
  }
  return str + suffix
}

function setCharAt(str, index, chr) {
  if (index > str.length - 1) return str
  return str.substring(0, index) + chr + str.substring(index + 1)
}

const formatToRupiah = (angka) => {
  if (angka == undefined) {
    return "Rp. 0,00"
  } else {
    return angka.toLocaleString("id-ID", {
      style: "currency",
      currency: "IDR",
    })
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function isObjectNotEmpty(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return true
    }
  }
  return false
}

function getFirstTwoWords(inputString) {
  try {
    const words = inputString.trim()
    if (words.length >= 3) {
      return words[0] + words[1] + words[2]
    } else {
      return inputString
    }
  } catch {
    return inputString
  }
}

function generateContrastingBackgroundColor() {
  // Ensure the generated color has enough contrast with white text
  const minContrastRatio = 4.5 // Minimum contrast ratio recommended by WCAG

  let bgColor
  do {
    // Generate a random hex color
    bgColor =
      "#" +
      Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, "0")
  } while (getContrastRatio(bgColor, "#FFFFFF") < minContrastRatio)

  return bgColor
}

function getContrastRatio(bgColor, textColor) {
  // Calculate the contrast ratio between two colors
  const colorLuminance = (color) => {
    const hex = color.slice(1)
    const r = parseInt(hex.slice(0, 2), 16) / 255
    const g = parseInt(hex.slice(2, 4), 16) / 255
    const b = parseInt(hex.slice(4, 6), 16) / 255

    const luminance = r * 0.299 + g * 0.587 + b * 0.114
    return luminance
  }

  const bgLuminance = colorLuminance(bgColor)
  const textLuminance = colorLuminance(textColor)

  if (bgLuminance > textLuminance) {
    return (bgLuminance + 0.05) / (textLuminance + 0.05)
  } else {
    return (textLuminance + 0.05) / (bgLuminance + 0.05)
  }
}

function convertToDecimal(number) {
  if (number >= 1000) {
    // return (number / 1000).toLocaleString("en-US", { maximumFractionDigits: 3 })
    return numeral(number).format("0,0")
  } else {
    return number.toString()
  }
}

export {
  uuidv4,
  toTitleCase,
  colonStrToJson,
  isJsonString,
  isEmail,
  hexColorIslight,
  isDeviation,
  isNullEmptyOrUndefined,
  doEval,
  shortenNumber,
  setCharAt,
  formatToRupiah,
  camelize,
  capitalizeFirstLetter,
  isObjectNotEmpty,
  getFirstTwoWords,
  generateContrastingBackgroundColor,
  convertToDecimal,
}
