import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/itemJadwal/"
export const serviceStokProduk = {
  index: (token, params = null) =>
    doRequest.get(feUrl + "stockProduk", params, token),
  get: (token, params = null) =>
    doRequest.get(feUrl + "stockProduk/getStokProduk", params, token),
  getById: (token, params = null) =>
    doRequest.post(feUrl + "stockProduk/getStokProduk", params, token),
  setPriority: (token, params = null) =>
    doRequest.post(feUrl + "stockProduk/setPriority", params, token),
  getDatatable: (token, params = null) =>
    doRequest.post(feUrl + "stockProduk/get", params, token),
  create: (token, params = null) =>
    doRequest.post(feUrl + "stockProduk/addUpdate", params, token),
  update: (token, params = null) =>
    doRequest.put(
      feUrl + "stockProduk/" + (params?.stockId ?? ""),
      params,
      token
    ),
  delete: (token, params = null) =>
    doRequest.post(feUrl + "stockProduk/delete", params, token),
}
