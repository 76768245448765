export const actions = {
  set_state: "dashboard/set_state",
  getShift: "dashboard/getShift",
  getPrevShift: "dashboard/getPrevShift",
  getTaskShift: "dashboard/getTaskShift",
  getPrevTaskShift: "dashboard/getPrevTaskShift",
  getScheduleShift: "dashboard/getScheduleShift",
  getPrevScheduleShift: "dashboard/getPrevScheduleShift",
  getHoHighlight: "dashboard/getHoHighlight",
  getUnscheduledRound: "dashboard/getUnscheduledRound",
  getCriticalEquipment: "dashboard/getCriticalEquipment",
  get_data_route: "dashboard/get_data_route",
}
