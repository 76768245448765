import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/dashboard/"

export const service = {
  getShift: (token, params = null) =>
    doRequest.get("dashboard/getShift", params, token),
  getTaskShift: (token, params = null) =>
    doRequest.get("dashboard/getTaskShift", params, token),
  getScheduleShift: (token, params = null) =>
    doRequest.get("dashboard/getScheduleShift", params, token),
  getHoHighlight: (token, params = null) =>
    doRequest.get("dashboard/getHoHighlight", params, token),
  getUnscheduledRound: (token, params = null) =>
    doRequest.get("dashboard/getSummaryUnscheduledRound", params, token),
  getCriticalEquipment: (token, params = null) =>
    doRequest.get("dashboard/getCriticalEquipment", params, token),
  getDataRoute: (token, params = null) =>
    doRequest.post(feUrl + "getDataRoute", params, token),
}
