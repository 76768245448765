/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from "redux-saga/effects"

import { actions } from "./action"
import { service } from "./api"
import { getAuthCredentials } from "@helpers/Auth"
import catchErrorResponse from "@helpers/catchErrorResponse"

function setState(payload) {
  return put({
    type: actions.set_state,
    payload,
  })
}

function load(payload) {
  return setState({ loading: payload })
}

export function* getIndex({ payload }) {
  yield setState({
    loading: true,
  })

  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(service.index, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      indexData: response.data?.data ?? {},
    })
  } else yield load(false)
}

export function* getDatatable({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(service.getDatatable, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      listData: response.data?.data ?? [],
      meta: {
        ...payload,
        ...{ total: response.data?.recordsTotal ?? 0 },
      },
    })
  } else yield load(false)
}

export default function* logSynchronizeSaga() {
  yield all([
    takeEvery(actions.get_index, getIndex),
    takeEvery(actions.get_datatable, getDatatable),
  ])
}
