import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/master/"
export const servicePasar = {
  index: (token, params = null) =>
    doRequest.get(feUrl + "pasar", params, token),
  get: (token, params = null) =>
    doRequest.post(feUrl + "pasar/getPasar", params, token),
  getById: (token, params = null) =>
    doRequest.post(feUrl + "pasar/getPasar", params, token),
  getDatatable: (token, params = null) =>
    doRequest.post(feUrl + "pasar/get", params, token),
  getCounting: (token, params = null) =>
    doRequest.post(feUrl + "pasar/getCounting", params, token),
  create: (token, params = null) =>
    doRequest.post(feUrl + "pasar/addUpdate", params, token),
  update: (token, params = null) =>
    doRequest.put(feUrl + "pasar/" + (params?.pasarId ?? ""), params, token),
  delete: (token, params = null) =>
    doRequest.post(feUrl + "pasar/delete", params, token),
  changeRadius: (token, params = null) =>
    doRequest.post(feUrl + "pasar/changeRadius", params, token),
}
