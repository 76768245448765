// ** Logo
// import logo from "@src/assets/images/logo/logo-primary.png"
import logo from "@src/assets/images/logo/catra-upaya-square.png"

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner app-loader">
      <img
        className="fallback-logo"
        src={logo}
        alt="logo"
        style={{ width: "50%", maxWidth: "100px" }}
      />
      <div className="loading" color="primary">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
