import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/itemJadwal/"
export const serviceJadwal = {
  get: (token, params = null) =>
    doRequest.post(feUrl + "jadwal/getJadwal", params, token),
  create: (token, params = null) =>
    doRequest.post(feUrl + "jadwal/addUpdate", params, token),
  downloadTemplate: (token, params = null) =>
    doRequest.post(feUrl + "jadwal/downloadTemplate", params, token),
  addUpdate: (token, params = null) =>
    doRequest.post(feUrl + "jadwal/addUpdateJadwal", params, token),
  exportJadwal: (token, params = null) =>
    doRequest.post(feUrl + "jadwal/exportJadwal", params, token),
  exportOutletJadwal: (token, params = null) =>
    doRequest.post(feUrl + "jadwal/exportOutletJadwal", params, token),
}
