import { actions } from "./action"

const initialState = {
  listData: [],
  rowData: {},
  meta: {
    total: 0,
    page: 1,
    perPage: -1,
    search: "",
    startDate: "1970-01-01",
    endDate: "1970-01-01",
    param1: "",
    param2: "",
    param3: "",
  },
  loading: false,
}

export const reducerRole = (state = initialState, action) => {
  switch (action.type) {
    case actions.set_state:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
