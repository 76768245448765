import { all } from "redux-saga/effects"
import dashboardSaga from "./dashboard/saga"
import appSaga from "./app/saga"
import userSaga from "./user/saga"
import permissionSaga from "./permission/saga"
import roleSaga from "./role/saga"
import authSaga from "./auth/saga"
import logActivitySaga from "./logActivity/saga"

import pasarSaga from "./pasar/saga"
import salesSaga from "./sales/saga"
import suplierSaga from "./suplier/saga"
import jenisSaga from "./jenis/saga"
import produkSaga from "./produk/saga"
import outletSaga from "./outlet/saga"

import kunjunganSaga from "./kunjungan/saga"
import pesananSaga from "./pesanan/saga"
import tagihanSaga from "./tagihan/saga"

import salesProdukSaga from "./salesProduk/saga"
import stokProdukSaga from "./stokProduk/saga"
import jadwalSaga from "./jadwal/saga"

import alamatSaga from "./alamat/saga"
import logSynchronizeSaga from "./logSynchronize/saga"
import syncSaga from "./sync/saga"
import pemilikSaga from "./pemilik/saga"

export default function* rootSaga() {
  yield all([
    authSaga(),
    dashboardSaga(),
    appSaga(),
    userSaga(),
    permissionSaga(),
    roleSaga(),
    logActivitySaga(),

    pasarSaga(),
    salesSaga(),
    suplierSaga(),
    jenisSaga(),
    produkSaga(),
    outletSaga(),

    kunjunganSaga(),
    pesananSaga(),
    tagihanSaga(),

    salesProdukSaga(),
    stokProdukSaga(),
    jadwalSaga(),

    alamatSaga(),
    logSynchronizeSaga(),
    syncSaga(),
    pemilikSaga(),
  ])
}
