/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from "redux-saga/effects"

import { actions } from "./action"
import { serviceLogin } from "./api"
import catchErrorResponse from "@helpers/catchErrorResponse"
import { removeAuthCredentials, setAuthCredentials } from "@helpers/Auth"
import jwtDecode from "jwt-decode"
import moment from "moment"

function setState(payload) {
  return put({
    type: "auth/login/set_state",
    payload,
  })
}

function load(payload) {
  return setState({ loading: payload })
}

export function* loginUser({ payload, callback }) {
  yield load(true)
  const response = yield call(serviceLogin.loginUser, payload)

  if (response?.status >= 200 && response?.status < 300) {
    let token = response?.data?.token
    let decrypt = jwtDecode(token)
    let resData = decrypt ?? {}

    let authCredData = {
      userId: resData?.data?.userId ?? "",
      username: resData?.data?.username ?? "",
      fullname: resData?.data?.name ?? "",
      deviceId: resData?.data?.deviceId ?? "",
      roleId: resData?.data?.roleId ?? "",
      role: resData?.data?.role ?? "",
      description: resData?.data?.description ?? "",
      sales: resData?.data?.sales ?? "",
      roles: resData?.data?.role ?? "",
      permission: (Array.isArray(resData?.role) ? resData?.role : []).join(","),
      loggedAt: resData?.loggedAt ?? new Date().valueOf(),
      fotoProfil: resData?.data?.fotoProfil ?? "",
      userApiToken: token ?? "",
      expiration: moment.unix(resData.exp).format("YYYY-MM-DD HH:mm:ss"),
    }
    setAuthCredentials(authCredData)

    if (callback) {
      callback({
        error: response?.status < 200 || response?.status >= 300,
        status: response?.status,
        message: response?.data?.status,
        data: { ...response.data, ...{ data: authCredData } },
      })
    }
    yield load(false)
  } else {
    yield setState({ errorMessage: response?.data, loading: false })
    catchErrorResponse(response)

    if (callback) {
      callback({
        error: response?.status < 200 || response?.status >= 300,
        status: response?.status,
        message: response?.data?.message,
        data: response?.data,
      })
    }
  }
}

function* logoutUser({ callback }) {
  try {
    yield load(true)
    removeAuthCredentials()

    yield load(false)
  } catch (error) {
    yield load(false)
  }
}

export default function* authSaga() {
  yield all([
    takeEvery(actions.authLogin, loginUser),
    takeEvery(actions.authLogout, logoutUser),
  ])
}
