import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import Spinner from '@components/spinner/Fallback-spinner'

const Loading = ({ loading }) => {
    const store = useSelector(state => state.layout)

    const [isLoad, setIsLoad] = useState(true)
    useEffect(() => {
        let loadTimer = setTimeout(() => { setIsLoad(loading) }, 500)

        return () => { clearTimeout(loadTimer) }
    }, [loading])

    return isLoad ? <div className='main-loader' style={{backgroundColor: store.skin == 'dark' ? '#161d31' : '#f8f8f8', zIndex: 1061}}><Spinner /></div> : null
}

export default Loading