import { actions } from "./action"

const initialState = {
  shiftData: [],
  prevShiftData: [],
  taskShiftData: [],
  prevTaskShiftData: [],
  scheduleShiftData: [],
  prevScheduleShiftData: [],
  hoHighlightData: [],
  unscheduledRoundData: [],
  criticalEquipmentData: [],
  rowData: {},
  dataRoute: {},
  loading: false,
}

export const reducerDashboard = (state = initialState, action) => {
  switch (action.type) {
    case actions.set_state:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
