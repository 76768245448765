/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from "redux-saga/effects"

import { actions } from "./action"
import { service } from "./api"
import catchErrorResponse from "@helpers/catchErrorResponse"
import { getAuthCredentials } from "@helpers/Auth"

function setState(payload) {
  return put({
    type: actions.set_state,
    payload,
  })
}

function load(payload) {
  return setState({ loading: payload })
}

export function* getDatatable({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(service.getDatatable, token, payload)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      listData: response.data?.data?.data ?? [],
      meta: {
        ...payload,
        ...{ total: response.data?.data?.recordsFiltered ?? 0 },
      },
    })
  } else yield load(false)
}

export function* getLast({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(service.getLast, token, payload)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      rowData: response.data?.data ?? {},
    })
  } else yield load(false)
}

export function* create({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(service.create, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export default function* appSaga() {
  yield all([
    takeEvery(actions.getLast, getLast),
    takeEvery(actions.get_datatable, getDatatable),
    takeEvery(actions.create, create),
  ])
}
