import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/master/"
export const serviceJenis = {
  get: (token, params = null) =>
    doRequest.post(feUrl + "jenis/getJenis", params, token),
  getById: (token, params = null) =>
    doRequest.post(feUrl + "jenis/getJenis", params, token),
  getDatatable: (token, params = null) =>
    doRequest.post(feUrl + "jenis/get", params, token),
  create: (token, params = null) =>
    doRequest.post(feUrl + "jenis/addUpdate", params, token),
  update: (token, params = null) =>
    doRequest.put(feUrl + "jenis/" + (params?.jenisId ?? ""), params, token),
  delete: (token, params = null) =>
    doRequest.post(feUrl + "jenis/delete", params, token),
}
