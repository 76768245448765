export const actions = {
  set_state: "produk/set_state",
  get_produk: "produk/get",
  get_produk_jadwal: "produk/get_produk_jadwal",
  get_produk_search: "produk/get_produk_search",
  set_priority: "produk/priority",
  get_index: "produk/get_index",
  get_by_id: "produk/get_by_id",
  get_datatable_produk: "produk/datatable",
  create_produk: "produk/create",
  update_produk: "produk/update",
  delete_produk: "produk/delete",
}
