export const actions = {
  set_state: "user/set_state",
  get_by_id: "user/get_by_id",
  get_account: "user/get_account",
  change_photo: "user/change_photo",
  get_datatable_user: "user/datatable",
  create_user: "user/create",
  update_user: "user/update",
  delete_user: "user/delete",
  check_ldap: "user/check_ldap",
}
