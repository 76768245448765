import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/master/"
export const serviceSales = {
  index: (token, params = null) =>
    doRequest.get(feUrl + "sales", params, token),
  get: (token, params = null) =>
    doRequest.post(feUrl + "sales/getSales", params, token),
  getSalesJadwal: (token, params = null) =>
    doRequest.post(feUrl + "sales/getSalesJadwal", params, token),
  getById: (token, params = null) =>
    doRequest.post(feUrl + "sales/getSales", params, token),
  getDatatable: (token, params = null) =>
    doRequest.post(feUrl + "sales/get", params, token),
  create: (token, params = null) =>
    doRequest.post(feUrl + "sales/addUpdate", params, token),
  update: (token, params = null) =>
    doRequest.put(feUrl + "sales/" + (params?.salesId ?? ""), params, token),
  delete: (token, params = null) =>
    doRequest.post(feUrl + "sales/delete", params, token),
  getDatatableTarget: (token, params = null) =>
    doRequest.post(feUrl + "sales/getTarget", params, token),
  createTarget: (token, params = null) =>
    doRequest.post(feUrl + "sales/addUpdateTarget", params, token),
  getPerformanceChart: (token, params = null) =>
    doRequest.post(feUrl + "sales/getPerformaChart", params, token),
  getPerformanceData: (token, params = null) =>
    doRequest.post(feUrl + "sales/getPerformaData", params, token),
  getPerformanceCount: (token, params = null) =>
    doRequest.post(feUrl + "sales/getPerformaCounting", params, token),
  importTargetSales: (token, params = null) =>
    doRequest.post(feUrl + "sales/importSalesTarget", params, token),
  downloadTemplate: (token, params = null) =>
    doRequest.get(feUrl + "sales/downloadTemplateTarget", params, token),
}
