import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/master/"
export const serviceOutlet = {
  index: (token, params = null) =>
    doRequest.get(feUrl + "outlet", params, token),
  get: (token, params = null) =>
    doRequest.post(feUrl + "outlet/getOutlet", params, token),
  getByPasar: (token, params = null) =>
    doRequest.post(feUrl + "outlet/getOutletPasar", params, token),
  getQrCode: (token, params = null) =>
    doRequest.post(feUrl + "outlet/generateQR", params, token),
  getById: (token, params = null) =>
    doRequest.post(feUrl + "outlet/getOutlet", params, token),
  getDatatable: (token, params = null) =>
    doRequest.post(feUrl + "outlet/get", params, token),
  getPermitLokasi: (token, params = null) =>
    doRequest.post(feUrl + "outlet/getPermitLokasi", params, token),
  accPermitLokasi: (token, params = null) =>
    doRequest.post(feUrl + "outlet/accPermitLokasi", params, token),
  create: (token, params = null) =>
    doRequest.post(feUrl + "outlet/addUpdate", params, token),
  update: (token, params = null) =>
    doRequest.put(feUrl + "outlet/" + (params?.outletId ?? ""), params, token),
  updateOutletInfo: (token, params = null) =>
    doRequest.post(feUrl + "outlet/updateInfoOutlet", params, token),
  delete: (token, params = null) =>
    doRequest.post(feUrl + "outlet/delete", params, token),
  getPerformanceChart: (token, params = null) =>
    doRequest.post(feUrl + "outlet/getPerformaChart", params, token),
  getPerformanceData: (token, params = null) =>
    doRequest.post(feUrl + "outlet/getPerformaData", params, token),
  getPerformanceCount: (token, params = null) =>
    doRequest.post(feUrl + "outlet/getPerformaCounting", params, token),
  changeRadius: (token, params = null) =>
    doRequest.post(feUrl + "outlet/changeRadius", params, token),
  changePasar: (token, params = null) =>
    doRequest.post(feUrl + "outlet/changePasar", params, token),
  exportOutlet: (token, params = null) =>
    doRequest.post(feUrl + "outlet/exportOutlet", params, token),
}
