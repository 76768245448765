import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/master/"
export const serviceSuplier = {
  index: (token, params = null) =>
    doRequest.get(feUrl + "suplier", params, token),
  get: (token, params = null) =>
    doRequest.post(feUrl + "suplier/getSuplier", params, token),
  getById: (token, params = null) =>
    doRequest.post(feUrl + "suplier/getSuplier", params, token),
  getDatatable: (token, params = null) =>
    doRequest.post(feUrl + "suplier/get", params, token),
  create: (token, params = null) =>
    doRequest.post(feUrl + "suplier/addUpdate", params, token),
  update: (token, params = null) =>
    doRequest.put(
      feUrl + "suplier/" + (params?.suplierId ?? ""),
      params,
      token
    ),
  delete: (token, params = null) =>
    doRequest.post(feUrl + "suplier/delete", params, token),
}
