import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/master/"
export const serviceAlamat = {
  get: (token, params = null) =>
    doRequest.post(feUrl + "alamat/getAlamat", params, token),
  getById: (token, params = null) =>
    doRequest.post(feUrl + "alamat/getAlamat", params, token),
  updateAlamatInfo: (token, params = null) =>
    doRequest.post(feUrl + "alamat/updateInfoAlamat", params, token),
}
