import moment from "moment"
import { actions } from "./action"

const initialState = {
  listData: [],
  rowData: {},
  indexData: {},
  listQrCode: [],
  permitData: {},
  listByPasar: [],
  performanceChart: {},
  performanceData: [],
  performanceCount: {},
  meta: {
    total: 0,
    page: 1,
    perPage: 10,
    search: "",
    tipe: "",
    tipeHarga: "",
    pasarId: "",
    status: "",
    statusRegis: "",
    statusPermitLokasi: "",
  },
  metaPerformanceData: {
    total: 0,
    page: 1,
    perPage: 10,
    search: "",
    bulanTahun: moment().format("MM-YYYY"),
  },
  loading: false,
}

export const reducerOutlet = (state = initialState, action) => {
  switch (action.type) {
    case actions.set_state:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
