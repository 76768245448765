export const actions = {
  set_state: "outlet/set_state",
  get_index: "outlet/get_index",
  get_by_id: "outlet/get_by_id",
  get_outlet: "outlet/get",
  get_outlet_by_pasar: "outlet/get_by_pasar",
  get_qrcode: "outlet/get_qrcode",
  get_datatable_outlet: "outlet/datatable",
  get_permit_lokasi: "outlet/get_permit_lokasi",
  acc_permit_lokasi: "outlet/acc_permit_lokasi",
  create_outlet: "outlet/create",
  delete_outlet: "outlet/delete",
  update_outlet: "outlet/update",
  update_outlet_info: "outlet/update_outlet_info",
  get_performance_chart: "outlet/get_performance_chart",
  get_performance_data: "outlet/get_performance_data",
  get_performance_count: "outlet/get_performance_count",
  change_radius: "outlet/change_radius",
  change_pasar: "outlet/change_pasar",
  export_outlet: "outlet/export",
}
