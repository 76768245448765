import { combineReducers } from "redux"

// ** Reducers Imports
import navbar from "./utils/navbar"
import layout from "./utils/layout"

import { reducerAuth } from "./auth/reducer"
import { reducerDashboard } from "./dashboard/reducer"
import { reducerApp } from "./app/reducer"
import { reducerUser } from "./user/reducer"
import { reducerPermission } from "./permission/reducer"
import { reducerRole } from "./role/reducer"
import { reducerPasar } from "./pasar/reducer"
import { reducerSales } from "./sales/reducer"
import { reducerSuplier } from "./suplier/reducer"
import { reducerJenis } from "./jenis/reducer"
import { reducerProduk } from "./produk/reducer"
import { reducerOutlet } from "./outlet/reducer"

import { reducerKunjungan } from "./kunjungan/reducer"
import { reducerPesanan } from "./pesanan/reducer"
import { reducerTagihan } from "./tagihan/reducer"

import { reducerSalesProduk } from "./salesProduk/reducer"
import { reducerStokProduk } from "./stokProduk/reducer"
import { reducerJadwal } from "./jadwal/reducer"

import { reducerAlamat } from "./alamat/reducer"

import { reducerLogActivity } from "./logActivity/reducer"
import { reducerLogSynchronize } from "./logSynchronize/reducer"
import { reducerSync } from "./sync/reducer"
import { reducerPemilik } from "./pemilik/reducer"

const rootReducers = combineReducers({
  navbar,
  layout,

  reducerAuth,

  reducerSales,
  reducerPasar,
  reducerSuplier,
  reducerJenis,
  reducerProduk,
  reducerOutlet,

  reducerDashboard,
  reducerApp,
  reducerUser,
  reducerPermission,
  reducerRole,
  reducerLogActivity,

  reducerKunjungan,
  reducerPesanan,
  reducerTagihan,

  reducerSalesProduk,
  reducerStokProduk,
  reducerJadwal,

  reducerAlamat,
  reducerPemilik,
  reducerLogSynchronize,
  reducerSync,
})

export default rootReducers
