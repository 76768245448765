/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from "redux-saga/effects"

import { actions } from "./action"
import { serviceAlamat, servicePemilik } from "./api"
import catchErrorResponse from "@helpers/catchErrorResponse"
import { getAuthCredentials } from "@helpers/Auth"

function setState(payload) {
  return put({
    type: "alamat/set_state",
    payload,
  })
}

function load(payload) {
  return setState({ loading: payload })
}

export function* updatePemilikInfo({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(servicePemilik.updatePemilikInfo, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export default function* pemilikSaga() {
  yield all([takeEvery(actions.update_pemilik_info, updatePemilikInfo)])
}
