import doRequest from "../../helpers/doRequest"
let feUrl = "other/"
export const service = {
  syncSupplier: (token, params = null) =>
    doRequest.get(feUrl + "syncronizeSuplier", params, token),
  syncProduct: (token, params = null) =>
    doRequest.get(feUrl + "syncronizeProduct", params, token),
  syncStock: (token, params = null) =>
    doRequest.get(feUrl + "syncronizeStok", params, token),
}
