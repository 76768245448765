import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/transaksi/"
export const serviceKunjungan = {
  index: (token, params = null) =>
    doRequest.get(feUrl + "kunjungan", params, token),
  get: (token, params = null) =>
    doRequest.get(feUrl + "kunjungan", params, token),
  getById: (token, params = null) =>
    doRequest.post(feUrl + "kunjungan/getKunjunganDetail", params, token),
  getDatatable: (token, params = null) =>
    doRequest.post(feUrl + "kunjungan/get", params, token),
}
