/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from "redux-saga/effects"

import { actions } from "./action"
import { serviceOutlet } from "./api"
import catchErrorResponse from "@helpers/catchErrorResponse"
import { getAuthCredentials } from "@helpers/Auth"

function setState(payload) {
  return put({
    type: actions.set_state,
    payload,
  })
}

function load(payload) {
  return setState({ loading: payload })
}

export function* get({ payload }) {
  yield setState({
    loading: true,
  })

  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceOutlet.get, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (response?.status === 200) {
    yield setState({
      loading: false,
      listData: response.data?.data ?? [],
    })
  } else yield load(false)
}

export function* getByPasar({ payload }) {
  yield setState({
    loading: true,
  })

  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceOutlet.getByPasar, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      listByPasar: response.data?.data ?? [],
    })
  } else yield load(false)
}

export function* getIndex({ payload }) {
  yield setState({
    loading: true,
  })

  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceOutlet.index, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      indexData: response.data?.data ?? {},
    })
  } else yield load(false)
}

export function* getById({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceOutlet.getById, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      rowData: response.data?.data[0] ?? {},
    })
  } else yield load(false)
}

export function* getQrCode({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceOutlet.getQrCode, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message ?? "",
      data: response?.data,
    })
  }
  yield load(false)
}

export function* getDatatable({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceOutlet.getDatatable, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (response?.status === 200) {
    yield setState({
      loading: false,
      listData: response.data?.data ?? [],
      meta: {
        ...payload,
        ...{ total: response.data?.recordsFiltered ?? 0 },
      },
    })
  } else yield load(false)
}

export function* getPermitLokasi({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceOutlet.getPermitLokasi, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      permitData: response.data?.data ?? {},
      meta: {
        ...payload,
        ...{ total: response.data?.recordsFiltered ?? 0 },
      },
    })
  } else yield load(false)
}

export function* accPermitLokasi({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceOutlet.accPermitLokasi, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* create({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceOutlet.create, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* update({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceOutlet.update, token, { ...payload })

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* updateOutletInfo({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceOutlet.updateOutletInfo, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* remove({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceOutlet.delete, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* getPerformaChart({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceOutlet.getPerformanceChart, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      performanceChart: response.data?.data ?? {},
    })
  } else yield load(false)
}

export function* getDatatablePerformaData({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceOutlet.getPerformanceData, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      performanceData: response.data?.data ?? [],
      metaPerformanceData: {
        ...payload,
        ...{ total: response.data?.recordsFiltered ?? 0 },
      },
    })
  } else yield load(false)
}

export function* getPerformaCount({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceOutlet.getPerformanceCount, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      performanceCount: response.data?.data.length
        ? response.data?.data[0]
        : {} ?? [],
    })
  } else yield load(false)
}

export function* changeRadius({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceOutlet.changeRadius, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* changePasar({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceOutlet.changePasar, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* exportOutlet({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceOutlet.exportOutlet, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export default function* outletSaga() {
  yield all([
    takeEvery(actions.get_outlet, get),
    takeEvery(actions.get_outlet_by_pasar, getByPasar),
    takeEvery(actions.get_index, getIndex),
    takeEvery(actions.get_by_id, getById),
    takeEvery(actions.get_qrcode, getQrCode),
    takeEvery(actions.get_datatable_outlet, getDatatable),
    takeEvery(actions.get_permit_lokasi, getPermitLokasi),
    takeEvery(actions.acc_permit_lokasi, accPermitLokasi),
    takeEvery(actions.create_outlet, create),
    takeEvery(actions.update_outlet, update),
    takeEvery(actions.update_outlet_info, updateOutletInfo),
    takeEvery(actions.delete_outlet, remove),
    takeEvery(actions.get_performance_chart, getPerformaChart),
    takeEvery(actions.get_performance_data, getDatatablePerformaData),
    takeEvery(actions.get_performance_count, getPerformaCount),
    takeEvery(actions.change_radius, changeRadius),
    takeEvery(actions.change_pasar, changePasar),
    takeEvery(actions.export_outlet, exportOutlet),
  ])
}
