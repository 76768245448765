/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from "redux-saga/effects"

import { actions } from "./action"
import { service } from "./api"
import catchErrorResponse from "@helpers/catchErrorResponse"
import { getAuthCredentials } from "@helpers/Auth"

function setState(payload) {
  return put({
    type: actions.set_state,
    payload,
  })
}

function load(payload) {
  return setState({ loading: payload })
}

export function* getDataRoute({ payload, callback }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(service.getDataRoute, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (response?.status === 200) {
    yield setState({
      loading: false,
      dataRoute: response.data?.data ?? {},
    })
  } else {
    yield load(false)
    yield setState({
      loading: false,
      dataRoute: {},
    })
  }

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
}

export default function* dashboardSaga() {
  yield all([takeEvery(actions.get_data_route, getDataRoute)])
}
