/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from "redux-saga/effects"

import { actions } from "./action"
import { serviceJenis } from "./api"
import catchErrorResponse from "@helpers/catchErrorResponse"
import { getAuthCredentials } from "@helpers/Auth"

function setState(payload) {
  return put({
    type: "jenis/set_state",
    payload,
  })
}

function load(payload) {
  return setState({ loading: payload })
}

export function* getJenis({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceJenis.get, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      listData: response.data?.data ?? [],
    })
  } else yield load(false)
}

export function* getById({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceJenis.getById, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      rowData: response.data?.data[0] ?? {},
    })
  } else yield load(false)
}

export function* getDatatableJenis({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceJenis.getDatatable, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      listData: response.data?.data ?? [],
      meta: {
        ...payload,
        ...{ total: response.data?.recordsFiltered ?? 0 },
      },
    })
  } else yield load(false)
}

export function* createJenis({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceJenis.create, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* updateJenis({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceJenis.update, token, { ...payload })

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* deleteJenis({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceJenis.delete, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export default function* jenisSaga() {
  yield all([
    takeEvery(actions.get_jenis, getJenis),
    takeEvery(actions.get_by_id, getById),
    takeEvery(actions.get_datatable_jenis, getDatatableJenis),
    takeEvery(actions.create_jenis, createJenis),
    takeEvery(actions.update_jenis, updateJenis),
    takeEvery(actions.delete_jenis, deleteJenis),
  ])
}
