export const actions = {
  get_index: "sales/get_index",
  set_state: "sales/set_state",
  get_sales: "sales/get",
  get_sales_jadwal: "sales/get_sales_jadwal",
  get_by_id: "sales/get_by_id",
  get_datatable_sales: "sales/datatable",
  create_sales: "sales/create",
  update_sales: "sales/update",
  delete_sales: "sales/delete",
  get_datatable_target: "sales/datatable_target",
  create_sales_target: "sales/create_target",
  update_sales_target: "sales/update_target",
  get_performance_chart: "sales/get_performance_chart",
  get_performance_data: "sales/get_performance_data",
  get_performance_count: "sales/get_performance_count",
  import_target_sales: "sales/import_target_sales",
  download_template: "sales/download_template",
}
