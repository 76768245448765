export const actions = {
  set_state: "salesProduk/set_state",
  get_salesProduk: "salesProduk/get",
  get_index: "salesProduk/get_index",
  get_by_id: "salesProduk/get_by_id",
  get_detail_by_id: "salesProduk/get_detail_by_id",
  get_datatable_salesProduk: "salesProduk/datatable",
  create_salesProduk: "salesProduk/create",
  update_salesProduk: "salesProduk/update",
  delete_salesProduk: "salesProduk/delete",
  dowmload_template: "salesProduk/download_template",
}
