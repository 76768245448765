import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/usman/"
export const serviceUser = {
  get: (token, params = null) =>
    doRequest.post(feUrl + "role/getRole", params, token),
  getById: (token, params = null) =>
    doRequest.post(feUrl + "role/getRole", params, token),
  getDatatable: (token, params = null) =>
    doRequest.post(feUrl + "role/get", params, token),
  create: (token, params = null) =>
    doRequest.post(
      feUrl + "role/addUpdate",
      params,
      token
      // "application/json-patch+json"
    ),
  update: (token, params = null) =>
    doRequest.put(feUrl + "role/" + (params?.roleId ?? ""), params, token),
  delete: (token, params = null) =>
    doRequest.post(feUrl + "role/delete", params, token),
}
