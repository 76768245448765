import { actions } from "./action"

const initialState = {
  listData: [],
  rowData: {},
  alamatPemilik: {},
  alamatOutlet: {},
  alamatPasar: {},
  indexData: {},
  meta: {
    total: 0,
    page: 1,
    perPage: 10,
    search: "",
  },
  loading: false,
}

export const reducerAlamat = (state = initialState, action) => {
  switch (action.type) {
    case actions.set_state:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
