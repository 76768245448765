import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { isObjectNotEmpty } from "./main"

const MySwal = withReactContent(Swal)

const catchErrorResponse = (response) => {
  const resData = response?.data ?? {}
  if (typeof resData === "object") {
    if (response.status == 401) {
      MySwal.fire({
        icon: "warning",
        title: "Opps! something wrong with status code " + response.status,
        text: "Your session has expired!, You will redirected to login page.",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((res) => {
        if (res.isConfirmed) {
          localStorage.removeItem("AUTH_CRED_SALES")
          localStorage.removeItem("RETURN_URL")
          return (window.location = "/login")
        }
      })
    } else {
      const resDataError = resData.messages ?? []
      if (typeof resDataError === "object" && isObjectNotEmpty(resDataError)) {
        MySwal.fire({
          icon: "warning",
          title: resData.message ?? "Warning",
          html: Object.values(resDataError)
            .map((item) => `<p key={k}>${item}</p>`)
            .join(" "),
        })
      } else if (Array.isArray(resDataError) && resDataError.length > 0) {
        MySwal.fire({
          icon: "warning",
          title: resData.message ?? "Warning",
          html: resDataError
            .map((item) => `<p key={k}>${item.errorMessage}</p>`)
            .join(" "),
        })
      } else {
        MySwal.fire({
          icon: "warning",
          title: "Opps! something wrong with status code " + response.status,
          text: resData?.message ?? resData?.message?.error ?? null,
        })
      }
    }
  } else {
    MySwal.fire({
      icon: "warning",
      title: "Opps! something wrong with status code " + response.status,
      text: resData?.message ?? response?.message ?? null,
    })
  }
}

export default catchErrorResponse
