import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/usman/"
export const serviceUser = {
  index: (token, params = null) =>
    doRequest.get(feUrl + "permission", params, token),
  get: (token, params = null) =>
    doRequest.post(feUrl + "permission/getPermission", params, token),
  getDatatable: (token, params = null) =>
    doRequest.post(feUrl + "permission/get", params, token),
  create: (token, params = null) =>
    doRequest.post(feUrl + "permission/addUpdate", params, token),
  update: (token, params = null) =>
    doRequest.put(
      feUrl + "permission/" + (params?.permissionId ?? ""),
      params,
      token
    ),
  delete: (token, params = null) =>
    doRequest.post(feUrl + "permission/delete", params, token),
}
