export const actions = {
  set_state: "suplier/set_state",
  get_index: "suplier/get_index",
  get_suplier: "suplier/get",
  get_by_id: "suplier/get_by_id",
  get_datatable_suplier: "suplier/datatable",
  create_suplier: "suplier/create",
  update_suplier: "suplier/update",
  delete_suplier: "suplier/delete",
}
