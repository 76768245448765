import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/itemJadwal/"
export const serviceSalesProduk = {
  index: (token, params = null) =>
    doRequest.get(feUrl + "salesProduk", params, token),
  get: (token, params = null) =>
    doRequest.get(feUrl + "salesProduk/getSalesProduk", params, token),
  getById: (token, params = null) =>
    doRequest.post(feUrl + "salesProduk/getSalesProduk", params, token),
  getDetailById: (token, params = null) =>
    doRequest.post(feUrl + "salesProduk/getSalesProdukDt", params, token),
  setPriority: (token, params = null) =>
    doRequest.post(feUrl + "salesProduk/setPriority", params, token),
  getDatatable: (token, params = null) =>
    doRequest.post(feUrl + "salesProduk/get", params, token),
  create: (token, params = null) =>
    doRequest.post(feUrl + "salesProduk/addUpdate", params, token),
  update: (token, params = null) =>
    doRequest.put(
      feUrl + "salesProduk/" + (params?.salesProdukId ?? ""),
      params,
      token
    ),
  delete: (token, params = null) =>
    doRequest.post(feUrl + "salesProduk/delete", params, token),
  downloadTemplate: (token, params = null) =>
    doRequest.get(feUrl + "salesProduk/downloadTemplate", params, token),
}
