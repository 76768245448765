import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/transaksi/"
export const serviceTagihan = {
  index: (token, params = null) =>
    doRequest.get(feUrl + "tagihan", params, token),
  get: (token, params = null) =>
    doRequest.post(feUrl + "tagihan/get", params, token),
  acceptTagihan: (token, params = null) =>
    doRequest.post(feUrl + "tagihan/acceptTagihan", params, token),
  updateTagihan: (token, params = null) =>
    doRequest.post(feUrl + "tagihan/updateTagihan", params, token),
}
