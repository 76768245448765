import moment from "moment"
import { actions } from "./action"

const initialState = {
  listData: [],
  listSalesJadwal: [],
  indexData: {},
  listDataTarget: [],
  rowData: {},
  rowDataTarget: {},
  performanceChart: {},
  performanceData: [],
  performanceCount: {},
  meta: {
    total: 0,
    page: 1,
    perPage: 10,
    search: "",
  },
  metaTarget: {
    total: 0,
    page: 1,
    perPage: 10,
    search: "",
    bulanTahun: moment().format("MM-YYYY"),
  },
  metaPerformanceData: {
    total: 0,
    page: 1,
    perPage: 10,
    search: "",
    bulanTahun: moment().format("MM-YYYY"),
  },
  loading: false,
}

export const reducerSales = (state = initialState, action) => {
  switch (action.type) {
    case actions.set_state:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
