export const actions = {
  set_state: "pesanan/set_state",
  review_pesanan: "pesanan/review_pesanan",
  batal_pesanan: "pesanan/batal_pesanan",
  accept_noo: "pesanan/accept_noo",
  register_outlet: "pesanan/register_outlet",
  get_pesanan: "pesanan/get",
  get_pesanan_detail: "pesanan/get_pesanan_detail",
  add_update_pesanan: "pesanan/add_update_pesanan",
  get_by_id: "pesanan/get_by_id",
  get_index: "pesanan/get_index",
  get_datatable_pesanan: "pesanan/datatable",
  get_tagihan: "pesanan/getTagihan",
  get_datatable_tagihan: "pesanan/datatable_tagihan",
  acc_tagihan: "pesanan/accept_tagihan",
  update_tagihan: "pesanan/update_tagihan",
  validate_tagihan: "pesanan/validate_tagihan",
  last_update_list: "pesanan/last_update_list",
}
