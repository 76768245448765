import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/master/"
export const serviceProduk = {
  index: (token, params = null) =>
    doRequest.get(feUrl + "produk", params, token),
  get: (token, params = null) =>
    doRequest.post(feUrl + "produk/getProduk", params, token),
  getProdukJadwal: (token, params = null) =>
    doRequest.post(feUrl + "produk/getProdukJadwal", params, token),
  getProdukSearch: (token, params = null) =>
    doRequest.post(feUrl + "produk/getProdukBySearch", params, token),
  getById: (token, params = null) =>
    doRequest.post(feUrl + "produk/getProduk", params, token),
  setPriority: (token, params = null) =>
    doRequest.post(feUrl + "produk/setPriority", params, token),
  getDatatable: (token, params = null) =>
    doRequest.post(feUrl + "produk/get", params, token),
  create: (token, params = null) =>
    doRequest.post(feUrl + "produk/addUpdate", params, token),
  update: (token, params = null) =>
    doRequest.put(feUrl + "produk/" + (params?.produkId ?? ""), params, token),
  delete: (token, params = null) =>
    doRequest.post(feUrl + "produk/delete", params, token),
}
