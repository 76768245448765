import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/usman/"
export const serviceUser = {
  getById: (token, params = null) =>
    doRequest.post(feUrl + "user/getUser", params, token),
  getDatatable: (token, params = null) =>
    doRequest.post(feUrl + "user/get", params, token),
  changePhoto: (token, params = null) =>
    doRequest.post("auth/changeFotoProfil", params, token),
  create: (token, params = null) =>
    doRequest.post(feUrl + "user/addUpdate", params, token),
  update: (token, params = null) =>
    doRequest.put(feUrl + "user/" + (params?.userId ?? ""), params, token),
  delete: (token, params = null) =>
    doRequest.post(feUrl + "user/delete", params, token),
}
