/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from "redux-saga/effects"

import { actions } from "./action"
import { serviceJadwal } from "./api"
import catchErrorResponse from "@helpers/catchErrorResponse"
import { getAuthCredentials } from "@helpers/Auth"

function setState(payload) {
  return put({
    type: "jadwal/set_state",
    payload,
  })
}

function load(payload) {
  return setState({ loading: payload })
}

export function* getJadwal({ payload }) {
  yield setState({
    loading: true,
  })
  const token = getAuthCredentials()?.userApiToken ?? ""

  const response = yield call(serviceJadwal.get, token, payload)
  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)
  if (response?.status === 200) {
    yield setState({
      loading: false,
      listData: response.data?.data ?? [],
    })
  } else yield load(false)
}

export function* createJadwal({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceJadwal.create, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* downloadTemplate({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceJadwal.downloadTemplate, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* exportJadwal({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceJadwal.exportJadwal, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* exportOutletJadwal({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceJadwal.exportOutletJadwal, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export function* addUpdate({ payload, callback }) {
  yield load(true)
  const token = getAuthCredentials()?.userApiToken ?? ""
  const response = yield call(serviceJadwal.addUpdate, token, payload)

  if (response?.status < 200 || response?.status >= 300)
    catchErrorResponse(response)

  if (callback) {
    callback({
      error: response?.status < 200 || response?.status >= 300,
      status: response?.status,
      message: response?.message,
      data: response?.data,
    })
  }
  yield load(false)
}

export default function* jadwalSaga() {
  yield all([
    takeEvery(actions.get_jadwal, getJadwal),
    takeEvery(actions.create_jadwal, createJadwal),
    takeEvery(actions.dowmload_template, downloadTemplate),
    takeEvery(actions.export_jadwal, exportJadwal),
    takeEvery(actions.export_outlet_jadwal, exportOutletJadwal),
    takeEvery(actions.add_update_jadwal, addUpdate),
  ])
}
