import doRequest from "../../helpers/doRequest"
let feUrl = "frontend/transaksi/"
export const servicePesanan = {
  index: (token, params = null) =>
    doRequest.get(feUrl + "pesanan", params, token),
  get: (token, params = null) =>
    doRequest.post(feUrl + "pesanan/getPesanan", params, token),
  getPesananDetail: (token, params = null) =>
    doRequest.post(feUrl + "pesanan/getDetail", params, token),
  getById: (token, params = null) =>
    doRequest.post(feUrl + "pesanan/getPesanan", params, token),
  reviewPesanan: (token, params = null) =>
    doRequest.post(feUrl + "pesanan/reviewPesanan", params, token),
  batalPesanan: (token, params = null) =>
    doRequest.post(feUrl + "pesanan/batalPesanan", params, token),
  acceptNoo: (token, params = null) =>
    doRequest.post(feUrl + "pesanan/accNewOutlet", params, token),
  registerOutlet: (token, params = null) =>
    doRequest.post(feUrl + "pesanan/accExsitingOutlet", params, token),
  addUpdatePesanan: (token, params = null) =>
    doRequest.post(feUrl + "pesanan/addUpdatePesanan", params, token),
  getDatatable: (token, params = null) =>
    doRequest.post(feUrl + "pesanan/get", params, token),
  getTagihan: (token, params = null) =>
    doRequest.post(feUrl + "pesanan/getTagihan", params, token),
  getDatatableTagihan: (token, params = null) =>
    doRequest.post(feUrl + "pesanan/getTagihanData", params, token),
  acceptTagihan: (token, params = null) =>
    doRequest.post(feUrl + "pesanan/acceptTagihan", params, token),
  updateTagihan: (token, params = null) =>
    doRequest.post(feUrl + "pesanan/updateTagihan", params, token),
  validateTagihan: (token, params = null) =>
    doRequest.post(feUrl + "pesanan/validateTagihan", params, token),
  lastUpdateList: (token, params = null) =>
    doRequest.post(feUrl + "pesanan/lastUpdateList", params, token),
}
